export const verbs1 = [
  { kana: 'いる', meaning: 'to be' },
  { kana: 'ある', meaning: 'to be (inanimate)', },
  { kana: 'する', meaning: 'to do' },
  { kana: '食べる', meaning: 'to eat', sound: 'たべる' },
  { kana: '飲む', meaning: 'to drink', sound: 'のむ' },
  { kana: '話す', meaning: 'to speak', sound: 'はなす' },
  { kana: '見る', meaning: 'to see', sound: 'みる' },
  { kana: '寝る', meaning: 'to sleep', sound: 'ねる'},
  { kana: '起きる', meaning: 'to wake', sound: 'おきる' },
  { kana: '買う', meaning: 'to buy', sound: 'かう' },
  { kana: '遊ぶ', meaning: 'to play', sound: 'あそぶ' },
  { kana: '着る', meaning: 'to wear', sound: 'きる' },
  { kana: '考える', meaning: 'to think', sound: 'かんがえる' },
  { kana: '分かる', meaning: 'to understand', sound: 'わかる'},
];

export const animals = [
  { kana: '度物', meaning: 'animal', sound: 'どぶつ' },
  { kana: '猫', meaning: 'cat', sound: 'ねこ' },
  { kana: '犬', meaning: 'dog', sound: 'いぬ' },
  { kana: '鳥', meaning: 'bird', sound: 'とり' },
  { kana: '魚', meaning: 'fish', sound: 'さかな' },
  { kana: '牛', meaning: 'cow', sound: 'うし' },
  { kana: '人', meaning: 'person', sound: 'ひと' },
];

export const adjectives1 = [
  { kana: 'かわいい', meaning: 'cute' },
  { kana: '甘い', meaning: 'sweet', sound: 'あまい' },
  { kana: '古い', meaning: 'old', sound: 'ふるい' },
  { kana: '楽しい', meaning: 'fun', sound: 'たのしい' },
  { kana: '簡単', meaning: 'easy', sound: 'かんたん' },
  { kana: '難しい', meaning: 'hard', sound: 'むずかしいです' },
  { kana: '丸い', meaning: 'round', sound: 'まるい' },
  { kana: '小さい', meaning: 'small', sound: 'ちいさい' },
  { kana: '正しい', meaning: 'correct', sound: 'ただしい' },
  { kana: '大きい', meaning: 'large', sound: 'おおきい' },
  { kana: '熱い', meaning: 'hot', sound: 'あつい' },
  { kana: '寒い', meaning: 'cold', sound: 'さむい' },
];

export const numbers = [
  { kana: 'れい', meaning: 'zero' },
  { kana: '一', meaning: 'one', sound: 'いち' },
  { kana: '二', meaning: 'two', sound: 'に' },
  { kana: '三', meaning: 'three', sound: 'さん' },
  { kana: '四', meaning: 'four', sound: 'よん' },
  { kana: '五', meaning: 'five', sound: 'ご' },
  { kana: '六', meaning: 'six', sound: 'ろく' },
  { kana: '七', meaning: 'seven', sound: 'なな' },
  { kana: '八', meaning: 'eight', sound: 'はち' },
  { kana: '九', meaning: 'nine', sound: 'きゅう' },
  { kana: '十', meaning: 'ten', sound: 'じゅう' },
];